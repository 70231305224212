<template>
  <div class="editorDataAll">
    <div class="headerMsg" v-cloak>
      <div class="leftBox">
        <div class="imgBox">
          <img :src="headerMsg.img_url" alt="" />
          <p>{{ headerMsg.status == 1 ? '已上架' : '已下架' }}</p>
        </div>
      </div>
      <div class="FlexBox">
        <div class="centerBox">
          <div class="titleBox">
            <h3>{{ headerMsg.name }}</h3>
            <div>
              <span class="cp" @click="open(headerMsg.ed_id, headerMsg.status)">
                {{ headerMsg.status == 1 ? '下架' : '上架' }}
              </span>
              <span class="fenge">|</span>
              <span class="cp" @click="delActivity(headerMsg.ed_id)">删除</span>
            </div>
          </div>
          <h4 :title="'简介：' + headerMsg.introduction">
            简介：{{
              headerMsg.introduction ? headerMsg.introduction : '未填写'
            }}
          </h4>
          <span>
            创建时间：{{
              headerMsg.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
            }}
          </span>
          <span style="margin: 0 5px">|</span>
          <span>创建人：{{ headerMsg.uname }}</span>
          <div class="priceBox">
            <h1>
              {{
                headerMsg.price_type == 1
                  ? '￥' + headerMsg.price
                  : headerMsg.price_type == 2
                  ? '免费'
                  : '密码:' + headerMsg.pwd
              }}
            </h1>
            <h2 v-if="headerMsg.crossed_price > 0 && headerMsg.price_type != 3">
              ￥{{ headerMsg.crossed_price }}
            </h2>
          </div>
          <div class="btnBox">
            <div>
              <p class="cp" @click="getShareInfo(headerMsg.ed_id)">分享/预览</p>
            </div>
            <div>
              <p @click="bianji(headerMsg.ed_id)">编辑</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footTabBox">
      <template>
        <el-tabs v-model="activeName" @tab-click="beforeLeave()">
          <el-tab-pane :label="'资料(' + firstList.length + ')'" name="first">
            <template>
              <div class="DataBtnBox">
                <div class="addData">
                  <p @click="chooseCourse_data()">添加资料</p>
                </div>
                <upload
                  ref="upload"
                  :meanwhile="2"
                  @start="complete({})"
                  @complete="complete"
                  accept=".ppt, .pptx , .doc , .docx , .pdf , .xls , .xmind ,  .xlsx , .txt , .rar , .zip"
                  class="upload-contain"
                >
                  <template #editsection_bt>
                    <div class="tianjia" @click="uploadStart = true"></div>
                    <div
                      class="upload-text vm upload-text"
                      @click="uploadStart = true"
                    >
                      本地上传
                    </div>
                  </template>
                </upload>
              </div>
              <div class="listData">
                <div class="noData" v-if="firstList.length == 0">
                  <p>未添加资料，请点击【添加资料】进行添加</p>
                </div>
                <div
                  class="list_1"
                  v-for="(item, index) in firstListSort"
                  :key="index"
                >
                  <div class="imgTitle">
                    <img
                      src="../../../assets/img/1.4.0.2/icon_zlbs@2x.png"
                      alt=""
                    />
                    <p :title="item.name">{{ item.name }}</p>
                  </div>
                  <div class="menuRight">
                    <p class="p">
                      {{ item.format }}
                    </p>
                    <span class="span">{{ item.size }}MB</span>
                    <template>
                      <div class="spanBtn">
                        <span
                          class="cp"
                          :class="{ disabled: index == 0 }"
                          @click="upFun(index, 1)"
                        >
                          上移
                        </span>
                        <span class="mr">|</span>
                        <span
                          class="cp"
                          :class="{
                            disabled: index == firstListSort.length - 1,
                          }"
                          @click="downFun(index, 1)"
                        >
                          下移
                        </span>
                        <span class="mr">|</span>
                        <el-popconfirm
                          confirm-button-text="确定"
                          placement="left"
                          :offset="20"
                          cancel-button-text="取消"
                          icon="el-icon-info"
                          icon-color="red"
                          @confirm="moveSaveClass(item.sort, 1)"
                          title="是否要移除该资料？"
                        >
                          <el-button type="text" slot="reference">
                            移除
                          </el-button>
                        </el-popconfirm>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="saveBtn_1">
                <div class="btn" @click="saveData()">
                  <p>保存</p>
                </div>
              </div>
            </template>
          </el-tab-pane>
          <el-tab-pane
            :label="'课程推荐(' + secondList.length + ')'"
            name="second"
          >
            <template>
              <div class="addCourseBox">
                <div class="addBtn">
                  <p @click="chooseCourse()">+选择课程</p>
                  <h3>
                    提示：添加课程只作为推荐，和售卖无关联；通过资料推送课程，引导学员报名课程
                  </h3>
                </div>
                <div class="listData">
                  <div class="noData" v-if="secondList.length == 0">
                    <p>未添加课程，请点击【选择课程】进行添加</p>
                  </div>
                  <div
                    class="list_1"
                    v-for="(item, index) in secondListSort"
                    :key="index"
                  >
                    <div class="imgTitle">
                      <img class="img" :src="item.photo" alt="" />
                      <p :title="item.name">{{ item.name }}</p>
                    </div>
                    <div class="menuRight">
                      <p class="p">
                        {{
                          item.course_type == 1
                            ? '直播课'
                            : item.courseType == 2
                            ? '小班课'
                            : item.courseType == 3
                            ? '录播课'
                            : '系列课'
                        }}
                      </p>
                      <span class="span" style="color: #ff3535">
                        ￥{{ item.price }}
                      </span>
                      <template>
                        <div class="spanBtn">
                          <span
                            class="cp"
                            :class="{ disabled: index == 0 }"
                            @click="upFun(index, 2)"
                          >
                            上移
                          </span>
                          <span class="mr">|</span>
                          <span
                            class="cp"
                            :class="{
                              disabled: index == secondListSort.length - 1,
                            }"
                            @click="downFun(index, 2)"
                          >
                            下移
                          </span>
                          <span class="mr">|</span>
                          <el-popconfirm
                            confirm-button-text="确定"
                            placement="left"
                            :offset="20"
                            cancel-button-text="取消"
                            icon="el-icon-info"
                            icon-color="red"
                            @confirm="moveSaveClass(item.sort)"
                            title="是否要移除该课程？"
                          >
                            <el-button type="text" slot="reference">
                              移除
                            </el-button>
                          </el-popconfirm>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="saveBtn_1">
                <div class="btn" @click="saveCourse()">
                  <p>保存</p>
                </div>
              </div>
            </template>
          </el-tab-pane>
          <el-tab-pane
            :label="
              '订单管理(' +
                (headerMsg.order_num ? headerMsg.order_num : '0') +
                ')'
            "
            name="third"
          >
            <orderfrom-data :type="3" :id="dataId"></orderfrom-data>
          </el-tab-pane>
        </el-tabs>
      </template>
    </div>
    <!--分享弹窗-->
    <sharePop
      v-if="shareId"
      @closeFn="toggleShareId"
      :shareInfo="shareInfo"
      type="2"
    ></sharePop>

    <uploadfile
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @completelist="completelist"
      @close="uploadConfirmClose"
      :typeselection="1"
      :title="'上传文件'"
    ></uploadfile>

    <el-dialog
      title="选择课程"
      width="850px"
      :visible.sync="dialogSelectVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div slot="title">
        选择课程
        <span style="color: #ff3535; font-size: 13px">(多选)</span>
      </div>
      <dialog-selectcourse ref="dialogRef" :type="2"></dialog-selectcourse>
    </el-dialog>

    <el-dialog
      title="选择资料"
      width="850px"
      :visible.sync="dialogSelectVisible_data"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div slot="title">
        选择资料
        <span style="color: #ff3535; font-size: 13px">(多选)</span>
      </div>
      <dialog-selectdata ref="dialogRef_data" :type="2"></dialog-selectdata>
    </el-dialog>
  </div>
</template>
<script>
// import bg from '@/mixin/background'
import dialogSelectcourse from '@/components/base/dialogSelectcourse'
import dialogSelectdata from '@/components/base/dialogSelectData'
import sharePop from '@/components/coursePop/sharePop'
import API from '@/request/api'
import upload from '@cm/base/Uploadtext'
import uploadfile from '@cm/base/UploadtextFile'
import orderfromData from '@cm/base/orderfromData.vue'

export default {
  components: {
    sharePop,
    upload,
    uploadfile,
    dialogSelectcourse,
    dialogSelectdata,
    orderfromData,
  },
  // mixins: [bg],
  data() {
    return {
      savesecondList: [],
      savefirstList: [],
      headerMsg: {},
      dialogSelectVisible_data: false, //控制选择资料
      dataId: '',
      // course: [], //选择的课程
      dialogSelectVisible: false, //控制选择课程
      sum: '', //给每一个上传的文档给一个index用来排序
      sum_1: '', //给每一个添加的课程给一个index用来排序
      uploadStart: false, //上传弹窗控制
      activeName: 'first',
      shareId: '', //二维码分享需要参数
      shareInfo: {}, //二维码分享需要参数
      firstList: [],
      secondList: [],
      firstListSort: [],
      secondListSort: [],
    }
  },
  mounted() {
    this.dataId = this.$route.params.id

    this.getHeader()
    this.getDataList()
    this.getCourseList()
  },
  watch: {},
  computed: {
    // firstListSort() {
    //   if (this.firstList.length != 0) {
    //     let val = _.assign([], this.firstList)
    //     return val.sort(function (a, b) {
    //       return a.sort - b.sort
    //     })
    //   } else {
    //     return []
    //   }
    // },
    // secondListSort() {
    //   if (this.secondList.length != 0) {
    //     let val = _.assign([], this.secondList)
    //     return val.sort(function (a, b) {
    //       return a.sort - b.sort
    //     })
    //   } else {
    //     return []
    //   }
    // },
  },
  methods: {
    getFileSize(value) {
      var size = (value / 1024 / 1024).toFixed(2)
      // }
      return size
    },
    beforeLeave() {
      // console.log(123)
      if (
        !(
          JSON.stringify(this.secondList) == JSON.stringify(this.savesecondList)
        )
      ) {
        this.saveCourse()
        this.savesecondList = Object.assign([], this.secondList)
      }
      if (
        !(JSON.stringify(this.firstList) == JSON.stringify(this.savefirstList))
      ) {
        this.saveData()
        this.savefirstList = Object.assign([], this.firstList)
      }
    },
    //获取关联的课程列表
    getCourseList() {
      this.$http({
        url: '/electronicsData/electronicsCourseList',
        data: {
          ed_id: this.dataId,
        },
        callback: data => {
          this.savesecondList = Object.assign([], data.data)
          this.secondList = Object.assign([], data.data)
          this.secondListSort = this.secondList.sort((a, b) => a.sort - b.sort)
          this.getSum_1()
        },
      })
    },
    //获取关联的资料列表
    getDataList() {
      this.$http({
        url: '/electronicsData/electronicsFileList',
        data: {
          ed_id: this.dataId,
        },
        callback: data => {
          this.savefirstList = Object.assign([], data.data)
          this.firstList = Object.assign([], data.data)
          this.firstListSort =
            this.firstList.length > 0
              ? this.firstList.sort((a, b) => a.sort - b.sort)
              : []
          this.getSum()
        },
      })
    },
    delActivity(id) {
      //删除
      this.$confirm(
        '删除后，已购买资料的学员自动失效，暂不支持还原资料和订单！',
        '删除资料',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/electronicsData/isDel',
          data: {
            ed_id: id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.$router.push({
              path: '/courseElectronics',
            })
          },
          error: () => {
            this.$root.prompt('删除失败')
          },
        })
      })
    },
    isShelves(type, id, status) {
      //1 上/下架    2展示/不展示到网校
      this.$http({
        url: '/electronicsData/isShelves',
        data: {
          type: type,
          ed_id: id,
          status: status,
        },
        callback: info => {
          this.$root.prompt({
            msg: info.info,
            type: 'success',
          })
          this.getHeader()
        },
      })
    },
    //上架下架
    open(id, status) {
      if (status == 1) {
        this.$confirm(
          '下架后已购买的学员仍可以查看资料，未购买的学员将无法购买，确认下架吗？',
          '下架资料',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.isShelves(1, id, 2)
        })
      } else {
        this.isShelves(1, id, 1)
      }
    },
    //编辑
    bianji(id) {
      this.$router.push({
        path: '/courseElectronics/newData/' + id,
      })
    },
    //获取头部的信息
    getHeader() {
      this.$http({
        url: '/electronicsData/getHeader',
        data: {
          ed_id: this.dataId,
        },
        callback: data => {
          this.headerMsg = Object.assign({}, data.data)
          // console.log(this.headerMsg)
        },
      })
    },
    saveCourse() {
      this.$http({
        url: '/electronicsData/saveElectronicsCourse',
        data: {
          ed_id: this.dataId,
          list: this.secondListSort,
        },
        callback: data => {
          this.$root.prompt({
            msg: data.info,
            type: 'success',
          })
        },
      })
    },
    saveData() {
      // console.log(this.firstListSort)
      this.$http({
        url: '/electronicsData/saveElectronicsFile',
        data: {
          ed_id: this.dataId,
          list: this.firstListSort,
        },
        callback: data => {
          this.$root.prompt({
            msg: data.info,
            type: 'success',
          })
        },
      })
    },
    gbDialog_data(list) {
      // console.log(list)
      //搭配组件使用的方法,关闭弹出框----------------
      this.dialogSelectVisible_data = false
      // for (let [key, value] of list.entries()) {
      //   value.sort = this.sum_1
      //   this.secondList.push(value)
      //   this.sum_1++
      // }
      // console.log(this.sum)
      list.forEach(value => {
        // console.log(value)
        value.sort = this.sum
        this.firstList.push(Object.assign({}, value))
        this.sum++
      })
      this.firstListSort =
        this.firstList.length > 0
          ? this.firstList.sort((a, b) => a.sort - b.sort)
          : []
    },
    cancelDialog_data() {
      this.dialogSelectVisible_data = false
    },
    //选择课程
    chooseCourse_data() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible_data = true
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        _this.$refs.dialogRef_data.getMenu_data(_this.firstList, _this.dataId)
        _this.$refs.dialogRef_data.clearSelect_data()
      })
    },
    gbDialog(list) {
      // console.log(list)
      //搭配组件使用的方法,关闭弹出框----------------
      this.dialogSelectVisible = false
      // for (let [key, value] of list.entries()) {
      //   value.sort = this.sum_1
      //   this.secondList.push(value)
      //   this.sum_1++
      // }
      list.forEach(value => {
        // console.log(value)
        value.sort = this.sum_1
        this.secondList.push(Object.assign({}, value))
        this.sum_1++
      })

      this.secondListSort =
        this.secondList.length > 0
          ? this.secondList.sort((a, b) => a.sort - b.sort)
          : []
    },
    cancelDialog() {
      this.dialogSelectVisible = false
    },
    //选择课程
    chooseCourse() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible = true
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        _this.$refs.dialogRef.getMenu(_this.secondList, _this.dataId)
        _this.$refs.dialogRef.clearSelect()
      })
    },
    getSum() {
      if (this.firstList.length != 0) {
        // console.log(this.firstList)
        this.sum = parseInt(this.firstList[this.firstList.length - 1].sort) + 1 //获取队列indexId的初始值
      } else {
        this.sum = 0
      }
    },
    getSum_1() {
      if (this.secondList.length != 0) {
        this.sum_1 =
          parseInt(this.secondList[this.secondList.length - 1].sort) + 1 //获取队列indexId的初始值
      } else {
        this.sum_1 = 0
      }
    },
    //上移方法
    upFun(index, type) {
      if (index == 0) {
        return
      }
      if (type == 1) {
        const old = this.firstListSort[index - 1]
        this.firstListSort.splice(index - 1, 1, this.firstListSort[index])
        this.firstListSort.splice(index, 1, old)
      } else {
        const old = this.secondListSort[index - 1]
        this.secondListSort.splice(index - 1, 1, this.secondListSort[index])
        this.secondListSort.splice(index, 1, old)
      }
    },
    //下移方法
    downFun(index, type) {
      if (type == 1) {
        if (index == this.firstListSort.length - 1) {
          return
        }

        const old = this.firstListSort[index + 1]
        this.firstListSort.splice(index + 1, 1, this.firstListSort[index])
        this.firstListSort.splice(index, 1, old)
      } else {
        if (index == this.secondListSort.length - 1) {
          return
        }

        const old = this.secondListSort[index + 1]
        this.secondListSort.splice(index + 1, 1, this.secondListSort[index])
        this.secondListSort.splice(index, 1, old)
      }
    },
    // 上传弹窗关闭
    uploadConfirmClose() {
      this.uploadStart = false
      // this.getJigouTeacher()
    },
    complete({
      //上传成功后
      serverId = '',
      file_size = '',
      file_name = '',
      type = '',
      format = '',
    }) {
      const option = {
        sort: this.sum,
        file_id: serverId,
        format: format,
        name: file_name,
        size: this.getFileSize(file_size),
        type: type,
      }
      // if (this.$store.state.userInfo.jg_is_drm == 1) {
      //   this.getSelectlist()
      // }
      this.firstList.push(option)
      this.sum++
    },
    completelist(val) {
      this.complete(val)
    },
    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },
    getShareInfo(course_id) {
      const self = this
      self.$http({
        name: API.coursePosterShare_Api.name,
        url: API.coursePosterShare_Api.url,
        data: {
          ed_id: course_id,
          type: '2',
        },
        callback({ code, data }) {
          if (code == 200) {
            self.shareId = course_id
            self.shareInfo = data
          }
        },
      })
    },
    moveSaveClass(id, type) {
      // //点击移除
      // console.log(id)
      if (type == 1) {
        for (let [key, value] of this.firstList.entries()) {
          if (value.sort == id) {
            this.firstList.splice(key, 1)
          }
        }
        for (let [key] of this.firstList.entries()) {
          // console.log(value.sort)
          this.firstList[key].sort = key //移除后重新设置indexid
        }
        this.getSum()
      } else {
        for (let [key, value] of this.secondList.entries()) {
          if (value.sort == id) {
            this.secondList.splice(key, 1)
          }
        }
        for (let [key] of this.secondList.entries()) {
          // console.log(value.sort)
          this.secondList[key].sort = key //移除后重新设置indexid
        }
        this.getSum_1()
        // console.log(this.secondList)
      }
    },
    // getFileSize(value) {
    //   var size = (value / 1024 / 1024).toFixed(2)
    //   // }
    //   return size
    // },
  },
  filters: {
    // getFileSize(value) {
    //   var size = value / 1024 / 1024 / 1024
    //   function getPointNum(num, n) {
    //     let str = String(num)
    //     let index = str.indexOf('.')
    //     let str1 = str.substring(0, index + n + 1)
    //     str1 = Number(str1)
    //     return str1
    //   }
    //   size = getPointNum(size, 3) * 1024
    //   return size > 0
    //     ? size.toFixed(2) + 'MB'
    //     : (0.001 * 1024).toFixed(2) + 'MB'
    // },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px 20px;
}
.saveBtn_1 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: white;
  padding: 7px 0 7px 208px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);

  .btn {
    background: #0aa29b;
    border: 1px solid #0aa29b;
    font-size: 14px;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    width: 100px;
    height: 40px;
    // margin-left: 40px;
    margin: 0 auto;
    margin-top: 5px;
    border-radius: 4px;
    text-align: center;

    p {
      line-height: 40px;
      font-size: 14px;
      color: #fff;
    }
  }
}

.disabled {
  color: #c0c4cc !important;
  cursor: not-allowed !important;
  opacity: 0.6;
}

.upload-text {
  font-size: 16px;
  line-height: 42px;
  margin: 0 15px;
  cursor: pointer;

  color: #0aa29b;
}

.tianjia {
  position: absolute;
  height: 42px;
  width: 90px;
  // background-color: red;
  top: 0px;
  left: 145px;
  cursor: pointer;
  opacity: 0.3;
}

.editorDataAll {
  width: 100%;
}

.footTabBox {
  min-width: 942px;
  box-sizing: border-box;
  border-top: 20px solid rgb(245, 245, 245);
  padding: 20px;
  padding-bottom: 95px;

  .addCourseBox {
    .addBtn {
      text-align: center;
      cursor: pointer;
      width: 126px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid #1b9d97;
      position: relative;

      p {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #0aa29b;
        line-height: 42px;
      }

      h3 {
        height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ff3535;
        line-height: 12px;
        font-weight: normal;
        width: 444px;
        position: absolute;
        left: 156px;
        top: 16px;
      }
    }
  }

  .DataBtnBox {
    display: flex;
    justify-content: space-between;
    width: 240px;
    position: relative;

    .uploadData {
      width: 100px;
      text-align: center;
    }

    .addData {
      width: 126px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid #1b9d97;
      text-align: center;
      cursor: pointer;
    }

    p {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #0aa29b;
      line-height: 42px;
      cursor: pointer;
    }
  }

  .listData {
    .noData {
      height: 60px;
      margin: 0 auto;
      text-align: center;

      p {
        color: #909399;
        font-size: 14px;
        line-height: 100px;
      }
    }

    .list_1 {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .el-button {
        padding: 0;
      }

      .imgTitle {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          width: 16px;
          height: 20px;
        }

        .img {
          width: 40px;
          height: 22.5px;
        }

        p {
          margin-left: 10px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 22.5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .menuRight {
        display: flex;
        min-width: 300px;
        justify-content: space-between;
        margin-left: 40px;

        .spanBtn {
          span {
            height: 13px;
            font-size: 13px;
            font-family: MicrosoftYaHei;
            color: #1b9d97;
            line-height: 13px;
          }

          .mr {
            margin: 0 5px;
          }
        }

        .p {
          width: 39px;
          height: 13px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #666666;
          line-height: 22.5px;
          margin-top: 2px;
        }

        .span {
          width: 47px;
          height: 13px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          line-height: 22.5px;
          color: #666666;
          margin-top: 2px;
        }

        a {
          width: 26px;
          height: 13px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #1b9d97;
          line-height: 22.5px;
          cursor: pointer;
        }
      }
    }
  }
}

.headerMsg {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 198px;
  padding: 20px;
  min-width: 942px;
  box-sizing: border-box;

  .leftBox {
    width: 280px;

    .imgBox {
      position: relative;

      img {
        width: 280px;
        height: 157.5px;
      }

      p {
        text-align: center;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 47px;
        height: 20px;
        background: #000000;
        opacity: 0.7;

        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .FlexBox {
    flex: 1;
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .centerBox {
      width: 100%;

      .titleBox {
        display: flex;
        justify-content: space-between;

        h3 {
          height: 20px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #363636;
          line-height: 20px;
          font-weight: normal;
        }

        div {
          .fenge {
            margin: 0 5px;
          }

          span {
            height: 14px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #1b9d97;
            line-height: 14px;
          }
        }
      }

      h4 {
        margin-top: 14px;
        height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 12px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span {
        height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 12px;
        margin-top: 10px;
      }

      .priceBox {
        display: flex;
        margin-top: 30px;

        h1 {
          font-size: 14px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ff3535;
          margin: 0;
        }

        h2 {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #999999;
          font-weight: normal;
          line-height: 14px;
          margin-left: 14px;
          text-decoration: line-through;
        }
      }

      .btnBox {
        width: 230px;
        display: flex;
        margin-top: 10px;

        p {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 30px;
        }

        div:nth-child(1) {
          width: 100px;
          height: 30px;
          background: #f39802;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
        }

        div:nth-child(2) {
          margin-left: 10px;
          width: 100px;
          height: 30px;
          background: #1b9d97;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .rightBox {
      background: red;
      width: 300px;
    }
  }
}
</style>
